module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/kamarmack/github/hats-dev/hats-dev-web-app/node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/kamarmack/github/hats-dev/hats-dev-web-app"},
    },{
      plugin: require('../node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HaTs","short_name":"HaTs","start_url":"/","display":"minimal-ui","icon":"images/logo-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c64bf10ef01a3ab1837a7d26bbdc9fbf"},
    },{
      plugin: require('../node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"author":"Kamar Mack","baseDirectory":"/Users/kamarmack/github/hats-dev/hats-dev-web-app","description":"HaTs is a super easy tool that improves your TypeScript development workflow.","githubRepositoryURL":"https://github.com/hats-dev/hats-dev-web-app","name":"HaTs","navItems":[{"title":"Home","url":"/docs/v1"}],"sections":["Getting Started","Development Workflow","CLI Reference"],"siteUrl":"https://hats.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CQDQN2SHDL"],"pluginConfig":{"head":true}},
    }]
